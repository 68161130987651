
import { defineComponent, inject } from 'vue'
import { RouteNames, GtmCategory, GtmEvents } from '@/enums'
import { gtmPush } from '@/helpers/gtm'
import { useUserDataStore } from '@/stores/data/UserDataStore'

export default defineComponent({
	name: 'Menu',
	setup() {
		return {
			userStore: useUserDataStore(),
			brand: inject('brand'),
		}
	},
	data: () => ({
		groups: [
			[
				{
					name: 'dashboard',
					icon: 'icon-dashboard-full',
					link: RouteNames.Dashboard,
					desktop: true,
				},
				{
					name: 'portfolio',
					icon: 'icon-portfolio-full',
					link: RouteNames.Portfolio,
					desktop: true,
				},
				{
					name: 'trade',
					icon: 'icon-trade',
					desktop: true,
					link: RouteNames.Trade,
				},
				{
					name: 'markets',
					icon: 'icon-markets-full',
					desktop: true,
					link: RouteNames.Markets,
				},
				{
					name: 'wallet',
					icon: 'icon-wallet-full',
					link: RouteNames.Wallet,
					desktop: true,
				},
				{
					name: 'tradingAcademy',
					icon: 'icon-education',
					link: 'https://orangito.com/trading-academy',
					desktop: true,
					target: '_blank',
					brand: 'orangito',
				},
			],
			[
				{
					name: 'settings',
					icon: 'icon-account-full',
					desktop: false,
					link: RouteNames.AccountSettings,
				},
				{
					name: 'support',
					icon: 'icon-support',
					desktop: false,
					link: RouteNames.AccountSupport,
				},
			],
		],
		classes:
			'text-text-secondary transition-all text-[11px] md:text-sm items-center flex flex-row py-1 mb-1 md:mb-3 ml-4 pl-6 md:pl-4 w-fit relative before:transition-all before:absolute before:h-full before:w-[3px] before:bg-primary before:rounded-sm before:opacity-0 hover:before:opacity-100 before:top-0 before:left-0 text-center md:text-left',
	}),
	methods: {
		menuClicked() {
			gtmPush(GtmCategory.GENERAL, GtmEvents.LEFT_NAVIGATION_USED)
		},
		logout() {
			gtmPush(GtmCategory.GENERAL, GtmEvents.LOGOUT)
			this.userStore.logout()
			this.$router.push({ name: RouteNames.SignIn })
		},
	},
})
